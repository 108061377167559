<template>
  <div class="hcontaner">
    <div class="hc-left">
      <img class="hcl-icon" src="@img/logo.png" alt="" />
      <span class="hcl-text">朝舜后台</span>
    </div>
    <div class="hc-right">
      <!-- <div class="other">其他</div> -->
      <a-dropdown>
        <div class="headPortrait">
          <img src="@img/header.png" alt="" class="img" />
          <span class="text">{{ userInfo.userName }}</span>
        </div>
        <template #overlay>
          <a-menu>
            <div class="menucontainer">
              <div class="header">
                <img class="img" src="@img/header.png" alt="" />
                <span class="text">{{ userInfo.userName }}</span>
              </div>
              <div class="row">其他信息</div>
              <div class="logout" @click="logout">退出登录</div>
            </div>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>
<script setup name="iHeader">
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
const mainRouter = useRouter();
let userInfo = ref({});

onMounted(() => {
  let store = localStorage.getItem(process.env.VUE_APP_LOGIN_INFO);
  if (store) {
    store = JSON.parse(store);
    userInfo.value = { ...store.adminInfo };
  }
});

let logout = () => {
  localStorage.setItem(process.env.VUE_APP_LOGIN_INFO, "");
  mainRouter.push("/login");
};
</script>
<style lang="less" scoped>
@import url("~@/style/mixin.less");
.hcontaner {
  width: 100vw;
  height: 56px;
  background: var(--themesBg);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .hc-left {
    width: 176px;
    height: 56px;
    padding: 0px 16px;
    display: flex;
    align-items: center;
    margin-top: 29px;
    .hcl-icon {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }
    .hcl-text {
      // font-family: "Noto Sans SC";
      // font-style: normal;
      // font-weight: 500;
      // font-size: 20px;
      // line-height: 28px;
      // color: #1d2129;
      .themeFontMixin();
    }
  }
  .hc-right {
    // align-self: flex-end;
    display: flex;
    align-items: center;
    cursor: pointer;
    .headPortrait {
      display: flex;
      padding: 0px 12px;
      gap: 8px;
      .img {
        width: 24px;
        height: 24px;
        background: #89b6df;
        border-radius: 12px;
      }
      .text {
        .themeFontMixin(14px,22px,);
      }
    }
  }
}
.menucontainer {
  width: 240px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  padding-top: 20px;
  overflow: hidden;
  .header {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    gap: 10px;
    .img {
      width: 40px;
      height: 40px;
      background: #89b6df;
      border-radius: 20px;
    }
    .text {
      .themeFontMixin(14px,22px);
    }
  }
  .row {
    display: flex;
    width: 200px;
    height: 22px;
    align-items: center;
    padding: 0px 20px;
  }
  .logout {
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    background: var(--ant-primary-color);
    .themeFontMixin(14px,22px,#FFF);
    &:hover {
      opacity: 0.6;
    }
  }
}
</style>>

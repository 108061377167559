// 路由信息

let iRouter = [
    {
        path: '/',
        redirect: { name: 'home' } // 将默认路由指向首页路由
    },
    {
        path: '/youKe',
        name: 'youKe',
        label: "改壳贴膜服务",
        component: () => import(/* webpackChunkName: "home" */ '@/views/login/youKe.vue'),
        meta: {
            alone: false,//是否为主页
        },
    },
    {
        path: '/login',
        name: 'login',
        label: "改壳贴膜服务",
        component: () => import(/* webpackChunkName: "home" */ '@/views/login/iLogin.vue'),
        meta: {
            alone: false,//是否为主页
        },
    },
    {
        path: '/home',
        name: 'home',
        label: "首页",
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/homeWord.vue')
    },
    {
        path: '/applicationList',
        name: 'applicationList',
        label: "申请列表",
        component: () => import(/* webpackChunkName: "applicationList" */ '@/views/applicationList/applicationList.vue')
    },
    {
        path: '/dealerList',
        name: 'dealerList',
        label: "经销商列表",
        component: () => import(/* webpackChunkName: "dealerList" */ '@/views/dealerList/dealerList.vue')
    },
    {
        path: '/dealerList/addDealerList',
        name: 'addDealerList',
        label: "新建经销商",
        meta: {
            isKeep: false,//不缓存
        },
        component: () => import(/* webpackChunkName: "dealerList" */ '@/views/dealerList/addDealerList.vue')
    },
    {
        path: '/dealerList/editDealerList',
        name: 'editDealerList',
        label: "编辑经销商",
        meta: {
            isKeep: false,//不缓存
        },
        component: () => import(/* webpackChunkName: "dealerList" */ '@/views/dealerList/editDealerList.vue')
    },
    {
        path: '/dealerList/detailDealerList',
        name: 'detailDealerList',
        label: "经销商详情",
        meta: {
            isKeep: false,//不缓存
        },
        component: () => import(/* webpackChunkName: "dealerList" */ '@/views/dealerList/detailDealerList.vue')
    },
    {
        path: '/renewalRecord',
        name: 'renewalRecord',
        label: "续费记录",
        // meta: {
        //     isKeep: false,//不缓存
        // },
        component: () => import(/* webpackChunkName: "renewalRecord" */ '@/views/renewalRecord/renewalRecord.vue')
    },
    {
        path: '/warrantyInfo',
        name: 'warrantyInfo',
        label: "质保信息",
        // meta: {
        //     isKeep: false,//不缓存
        // },
        component: () => import(/* webpackChunkName: "warrantyInfo" */ '@/views/warrantyInfo/warrantyInfo.vue')
    },
    {
        path: '/warrantyInfo/addwarrantyInfo',
        name: 'addwarrantyInfo',
        label: "新增质保信息",
        meta: {
            isKeep: false,//不缓存
        },
        component: () => import(/* webpackChunkName: "warrantyInfo" */ '@/views/warrantyInfo/addwarrantyInfo.vue')
    },
    {
        path: '/warrantyInfo/editwarrantyInfo',
        name: 'editwarrantyInfo',
        label: "编辑质保信息",
        meta: {
            isKeep: false,//不缓存
        },
        component: () => import(/* webpackChunkName: "warrantyInfo" */ '@/views/warrantyInfo/editwarrantyInfo.vue')
    },
    {
        path: '/warrantyInfo/detailwarrantyInfo',
        name: 'detailwarrantyInfo',
        label: "质保信息详情",
        meta: {
            isKeep: false,//不缓存
        },
        component: () => import(/* webpackChunkName: "warrantyInfo" */ '@/views/warrantyInfo/detailwarrantyInfo.vue')
    },


];

export {
    iRouter,
}
<template>
  <div class="c-container">
    <div class="c-menu">
      <i-menu :routerId="routerId" />
    </div>
    <div class="c-content">
      <nav-title :routerStr="routerStr" />
      <div class="cc-content">
        <!-- <a-config-provider :locale="ilocale"> -->
        <router-view v-slot="{ Component }" v-if="isKeepAlice">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
        <router-view v-else />
        <!-- </a-config-provider> -->
      </div>
    </div>
  </div>
</template>
<script setup>
// import dayjs from "dayjs";
// import "dayjs/locale/zh-cn";
// import locale from "ant-design-vue/es/locale/zh_CN";
// dayjs.locale("zh-cn");
import navTitle from "@/components/admin/navTitle";
import iMenu from "@/components/admin/iMenu";
import { useRoute } from "vue-router";
import { watch, ref, onMounted } from "vue";
import { mrouter } from "@/router/mrouter";
// let ilocale = ref(locale);
const route = useRoute();
watch(
  route,
  (to) => {
    to = JSON.parse(JSON.stringify(to));
    console.log("路由变化....", to);
    // route.meta.keepAlive
    isKeepAlice.value = to.meta.isKeep;
    analyticRouting(to.path);
  },
  { deep: true, immediate: false }
);
let isKeepAlice = ref(true); //默认缓存页面
onMounted(() => {
  // 初始化的时候执行一次
  let routes = route.path;
  analyticRouting(routes);
  console.log("传递参数。。。。", routerId.value);
});
let routerId = ref([]);
let routerStr = ref([]);
// 解析路由
let analyticRouting = (res) => {
  routerStr.value = [];
  routerId.value = [];
  for (let item of mrouter) {
    if (item.path == res) {
      // 一级
      routerStr.value.push(item.name);

      routerId.value.push(item.menuId);
      return;
    } else if (item.children && item.children.length > 0) {
      for (let child of item.children) {
        if (child.path == res) {
          // 二级
          routerStr.value.push(item.name);
          routerStr.value.push(child.name);

          routerId.value.push(item.menuId);
          routerId.value.push(child.menuId);
          return;
        } else {
          if (child.children && child.children.length > 0) {
            for (let ele of child.children) {
              if (ele.path == res) {
                // 三级
                routerStr.value.push(item.name);
                routerStr.value.push(child.name);
                routerStr.value.push(ele.name);

                routerId.value.push(item.menuId);
                routerId.value.push(child.menuId);
                return;
              }
            }
          }
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
.c-container {
  background: var(--themesBg);
  display: flex;
  .c-menu {
    width: 160px;
    height: calc(100vh - 56px);
  }
  .c-content {
    margin-left: 16px;
    // width: 100%;
    width: calc(100vw - 176px);
    height: 100%;
    .cc-content {
      width: 100%;
      // width:calc(100vw -176px);
      height: calc(100vh - 86px);
      // background: var(--themesBg);
      overflow-y: scroll;
      overflow-x: hidden;
      /* 修改滚动条轨道的颜色 */
      &::-webkit-scrollbar-track {
        background-color: #91d5ff;
      }

      /* 修改滚动条滑块的颜色 */
      &::-webkit-scrollbar-thumb {
        background-color: var(--ant-primary-color);
      }

      /* 增加滚动条的宽度 */
      &::-webkit-scrollbar {
        width: 3px;
      }

      /* 给滑块添加圆角和阴影效果 */
      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
      }
    }
  }
}
</style>>


/**
 * 存储登录信息
 */
export function storeToken(result) {
    let store = sessionStorage.getItem(process.env.VUE_APP_LOGIN_INFO);
    // console.log("存储登录信息", result, store);
    if (result) {
        sessionStorage.setItem(process.env.VUE_APP_LOGIN_INFO, JSON.stringify(result));
        return
    }
    if (store && store != "undefined") {
        return JSON.parse(store);
    }
    return "";
}

/**
 * get参数序列化
 */
export function serialization(obj, url) {
    if (typeof obj !== 'object' && obj == null) return;
    if (!url) return;
    let queryParams = [];

    for (let key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            // if (obj.hasOwnProperty(key)) {
            let encodedKey = encodeURIComponent(key);
            let encodedValue = encodeURIComponent(obj[key]);
            queryParams.push(encodedKey + '=' + encodedValue);
        }
    }

    return url + '?' + queryParams.join('&');

}

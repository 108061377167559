<template>
  <a-config-provider
    :locale="ilocale"
    colors="{{ primary: '#FF0000', secondary: '#00FF00', accent: '#0000FF' }}"
  >
    <div class="main">
      <template v-if="isAlone">
        <div class="main-app">
          <i-header />
          <i-content />
        </div>
      </template>
      <template v-else>
        <div class="main-app main-container">
          <router-view />
        </div>
      </template>
    </div>
  </a-config-provider>
</template>
<script setup>
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import locale from "ant-design-vue/es/locale/zh_CN";
dayjs.locale("zh-cn");
import iHeader from "@/components/admin/iHeader";
import iContent from "@/components/admin/iContent";

import { useRoute } from "vue-router";
import { watch, ref } from "vue";

let ilocale = ref(locale);
const route = useRoute();
let isAlone = ref(false); //是否为主页
watch(
  () => route,
  (val) => {
    //没有值的默认为主页
    let alone = val.meta && val.meta.alone == false ? false : true;
    isAlone.value = alone;
  },
  { deep: true, immediate: false }
);
// let getPopupContainer = (el, dialogContext) => {
//   if (dialogContext) {
//     return dialogContext.getDialogWrap();
//   } else {
//     return document.body;
//   }
// };
</script>
<style lang="less">
:root {
  /* 主题色 */
  --themesBg: #f0f2f5;
}
.main-app {
  width: 100vw;
  height: 100vh;
  // overflow-y: scroll;
  overflow: hidden;
  &.main-container {
    overflow-y: scroll;
  }
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background-color: #91d5ff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--ant-primary-color);
  }
}
</style>
